<template>
  <section class="af_b_margin_big">
            <h4 class="strong" :style="$root.textColor">Typical questions asked about product:</h4>
            <ul>
                <li :style="$root.textColor">How easy this item to use?</li>
                <li :style="$root.textColor">Is it durable?</li>
                <li :style="$root.textColor">What is the size of it?</li>
            </ul>
        </section>
</template>

<script>
export default {
  name: 'NoQA',
  props: {

  }
}
</script>
