<template>
    <div>
  <img src="@/assets/loader.svg" alt="Loading..." :width="width" >
    <h2 :style="$root.textColor">Loading...</h2>
    </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    width: String
  }
}
</script>
