<template>
<div class="af_flex af_flex_row_reverse af_b_margin_big" v-if="propTotalRecords>propPageLimit">
  <ul class="af_pagination">
    <li v-bind:class="['af_prev', {'af_disabled':currentPage==1}]" :style="[currentPage==1 ? $root.secondaryColor : $root.textColor]"  @click="paginate(prevOffset, prevPageNumber)">❮ Prev</li>
    <li v-if="propCurrentIndex>=pageNumbersLimit-LeftRightPageLimit && totalPages>pageNumbersLimit" :style="$root.textColor" @click="paginate(0, 1)">First</li>
    <li :style="[currentPage==value ? $root.activePage : $root.textColor]" v-bind:class="{'active':currentPage==value}" @click="paginate(value-1, value)" v-for="(value) in visiblePages" v-bind:key="value">{{value}}</li>
    <li v-if="propCurrentIndex<((totalPages-1)-LeftRightPageLimit) && totalPages>pageNumbersLimit" :style="$root.textColor" @click="paginate(totalPages-1, totalPages)">Last</li>
    <li v-bind:class="['af_prev', {'af_disabled':currentPage==totalPages}]" :style="[currentPage==totalPages ? $root.secondaryColor : $root.textColor]" @click="paginate(nextOffset, nextPageNumber)">Next ❯</li>
  </ul>
  <!-- <div class="af_pagination">
    <a v-bind:class="['af_prev', {'af_disabled':currentPage==1}]" :style="[currentPage==1 ? $root.secondaryColor : $root.textColor]" href="javascript:void(0)" @click="paginate(prevOffset, prevPageNumber)">❮ Prev</a>
    <a v-if="propCurrentIndex>=pageNumbersLimit-LeftRightPageLimit && totalPages>pageNumbersLimit" href="javascript:void(0)" :style="$root.textColor" @click="paginate(0, 1)">First</a>
    <a :style="[currentPage==value ? $root.activePage : $root.textColor]" v-bind:class="{'active':currentPage==value}" href="javascript:void(0)" @click="paginate(value-1, value)" v-for="(value) in visiblePages" v-bind:key="value">{{value}}</a>
    <a v-if="propCurrentIndex<((totalPages-1)-LeftRightPageLimit) && totalPages>pageNumbersLimit" href="javascript:void(0)" :style="$root.textColor" @click="paginate(totalPages-1, totalPages)">Last</a>
    <a v-bind:class="['af_prev', {'af_disabled':currentPage==totalPages}]" :style="[currentPage==totalPages ? $root.secondaryColor : $root.textColor]" href="javascript:void(0)" @click="paginate(nextOffset, nextPageNumber)">Next ❯ </a>
  </div> -->
</div>
</template>

<script>
export default {
  name: 'Paging',
  data () {
    return {
      pageNumbersLimit: 5, // Default number of pages to be shown
      LeftRightPageLimit: 2,
      showFirst: false,
      showLast: false,
      allPages: [] // Empty array
    }
  },
  props: {
    propTotalRecords: { type: Number, required: true }, // Getting total records from parent component
    propPageLimit: { type: Number, required: true }, // Getting the page limit from parent component
    propCurrentIndex: { type: Number, required: true } // Getting the active index/page
  },
  methods: {
    // Method to create a custom event that will notify the parent component on page number click
    paginate: function (index, pageNumber) {
      this.$emit('paginate', this.propPageLimit * index, index)
    }
  },
  computed: {
    totalPages: function (e) { // Calculating the total number of pages
      return Math.ceil(this.propTotalRecords / this.propPageLimit)
    },
    visiblePages: function (e) { // Calculating visible page numbers based on limit
      let allPages = []
      let i = 1
      for (i; i <= this.totalPages; i++) { // Converting the total pages count to array
        allPages[i - 1] = i
      }

      if (this.totalPages > this.pageNumbersLimit) { // If total pages are greater than the page number limit
        // this.$Console.log(allPages.slice(this.propCurrentIndex, this.pageNumbersLimit + this.propCurrentIndex))
        let startIndex = this.propCurrentIndex - this.LeftRightPageLimit
        let endIndex = this.pageNumbersLimit + startIndex
        if (startIndex < 1) {
          startIndex = 0
        }

        // if (startIndex > this.pageNumbersLimit) {
        //   this.showFirst = true
        // }
        return allPages.slice(startIndex, endIndex) // Showing numbers as per limit
      } else {
        return allPages // Else all
      }
    },
    currentPage: function () {
      return this.propCurrentIndex + 1
    },
    nextOffset: function () {
      return this.currentPage
    },
    nextPageNumber: function () {
      return this.currentPage + 1
    },
    prevOffset: function () {
      return this.propCurrentIndex - 1
    },
    prevPageNumber: function () {
      return this.currentPage - 1
    }

  }
}
</script>
