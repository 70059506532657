<template>
  <p v-if="propAnswer.length>220" :style="[$root.textColor,$root.sizeBody]" itemprop="text">
                <template v-if="hideAnswer">
                  {{propAnswer.substr(0, 220)}}
                  ... <a href="javascript:void(0)" :style="[$root.primaryColorButton,$root.sizeLink]" @click="hideAnswer = !hideAnswer">see more</a>
                </template>
                <template v-else>
                  <span>{{propAnswer}}</span>
                  ... <a href="javascript:void(0)" :style="[$root.primaryColorButton,$root.sizeLink]" @click="hideAnswer = !hideAnswer">see less</a>
                </template>
              </p>
              <p v-else :style="[$root.textColor,$root.sizeBody]" itemprop="text">{{propAnswer}}</p>
</template>

<script>
export default {
  name: 'TrimmedAnswer',
  props: {
    propAnswer: String
  },
  data () {
    return {
      hideAnswer: true
    }
  },
  methods: {

  }
}
</script>
