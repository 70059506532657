<template>
  <div v-if="!loading" class="af_container">
    <div v-if="authorized">
        <div class="af_header af_b_margin af_b_padding">
            <!-- <h1 class="af_page_heading af_b_margin" :style="[$root.textColor,$root.sizeH1]">Customer Q<span class="af_text_hide">uestions </span>&<span class="af_text_hide">&nbsp;</span>A<span class="af_text_hide">nswers</span></h1> -->
            <p class="af_searchbox" v-if="$root.displayBadge" style="font-size:13px;text-align:right;margin-bottom:8px;" :style="[$root.textColor]"><span class="af_text_muted">Powered by</span>
            &nbsp;<a href="https://www.aframark.com" title="Syndicated Question & Answer Solution by Aframark" target="_blank" :style="[$root.primaryColorButton]">Aframark</a> </p>
            <form class="af_searchbox" v-on:submit.prevent>
                <input @keyup="searchQuestion" v-model="searchTerm" id="input-text" type="text"
                    placeholder="Have a question? Search for answers" autocomplete="off">

                <div class="af_ask_btn af_t_margin af_b_padding af_flex af_brd_btm" v-if="showButton">
        <p class="af_align_self_center" :style="[$root.textColor,$root.sizeBody]">Don't see what you are looking for?</p>
        <router-link style="text-align:center" rel="nofollow" :style="[$root.primaryColorButton,$root.sizeBody]" :to="{ name: 'askquestion', params: { propQuestion: question, merchantcode: merchantcode, sku: sku, domain:domain } }" tag="a" class="af_btn">
                    Ask Community
                </router-link>

        <button type="reset" @click="clearSearch" :style="$root.sizeBody" style="margin-left: 15px" class="af_align_self_center af_text_muted af_no_line">Clear</button>
        </div>
            </form>

        </div>

        <!-- Section when no Q&A available -->
        <NoQA v-if="!allQA.data && !searchTerm"></NoQA>

        <!-- Section when Q&A available -->
        <div v-if="allQA && !searchLoading">
          <small v-if="allRecords>0" class="af_text_muted ml-auto af_full_width af_b_margin" :style="[$root.textColor,$root.sizeSmall]">Showing {{showingFrom}}-{{showingTill}} of {{allRecords}} questions</small>
          <section class="af_b_margin_big" itemscope itemtype="https://schema.org/Question" v-for="(value) in allQA.data.allQA" v-bind:key="value.question_id">
        <div class="af_flex">
      <!-- Votes -->
      <Votes :questionID="value.question_id" :totalVotes="value.total_votes"></Votes>

      <div class="af_q&a af_flex af_flex_column af_full_width af_ie10_flex_width_fix">
        <div class="af_row af_question af_flex af_border_left af_border_position">
          <div class="af_w_fixed1 af_left">
            <h4 :style="[$root.textColor,$root.sizeBody]">Q<span class="af_text_hide">uestion</span>:</h4>
          </div>
          <div class="af_qcontent af_user_question af_w_fixed2 af_left af_ie10_flex_width_fix">
            <h4 :style="[$root.textColor,$root.sizeBody]" itemprop="name" class="af_trim af_twoline_tt " id="text">
              <span v-html="value.customer_question">
              </span>
              <small class=" af_text_muted ml-auto af_full_width af_t_margin_sml normal-weight" :style="[$root.textColor,$root.sizeSmall]">
                <span itemprop="author" itemscope itemtype="https://schema.org/Person">
                  <span itemprop="name" style="text-transform: capitalize;">{{value.customer_name}}</span>
                </span> on <span itemprop="dateCreated">{{value.created_at}}</span> </small>
            </h4>

          </div>
        </div>
        <div class="af_row af_answers af_flex af_border_left af_border_position">
          <div class="af_w_fixed1 af_left">
            <h4 :style="[$root.textColor,$root.sizeBody]">A<span class="af_text_hide">nswers</span>:</h4>
          </div>
          <div class="af_acontent af_w_fixed2 af_left af_ie10_flex_width_fix">
            <div itemprop="suggestedAnswer" itemscope itemtype="https://schema.org/Answer" class="af_user_answer">

              <!-- Trimmed Answer -->
              <TrimmedAnswer :propAnswer="value.answers[0].community_answer"></TrimmedAnswer>
              <small class="af_text_muted ml-auto af_full_width af_t_margin_sml" :style="[$root.textColor,$root.sizeSmall]">By
                <span itemprop="author" itemscope itemtype="https://schema.org/Person">
                  <span itemprop="name">{{value.answers[0].answered_by_name}}</span>
                </span> on <span itemprop="dateCreated">{{value.answers[0].created_at}}</span> </small>

              <!-- Helpful component -->
              <Helpful :propanswerID=value.answers[0].answer_id :propHelpfulCount="value.answers[0].helpful" :propNothelpfulCount="value.answers[0].not_helpful"></Helpful>
            </div>
          </div>
        </div>
        <div class="af_row af_answers af_flex af_t_margin_sml af_border_position ">
          <div class="af_w_fixed1 af_left"></div>
          <div v-if="value.total_answers>1"  class="af_footer_content af_w_fixed2 af_flex af_justify_content_between af_align_items_bottom">
          <router-link class="af_primary_color" rel="nofollow" :style="[$root.primaryColor,$root.sizeLink]" :to="{ name: 'allanswers',params: { qid: value.question_id, question: customerQuestion(value.customer_question,value.customer_question_plain), created_at: value.created_at, merchantcode: merchantcode, sku: sku, domain:domain } }">See more answers ({{value.total_answers-1}})</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

    <!-- Paging -->
    <Paging :propTotalRecords=allRecords :propCurrentIndex=this.currentIndex :propPageLimit=this.limit v-on:paginate="paginateSearch"></Paging>

        </div>
        <div v-else style="text-align:center">
          <Loader width="80px"></Loader>
        </div>
        </div>
        <div v-else>--</div>
    </div>
    <div v-else style="text-align:center">
        <Loader width="120px"></Loader>
    </div>

</template>

<script>
// @ is an alias to /src
import Votes from '@/components/Votes.vue'
import Helpful from '@/components/Helpful.vue'
import NoQA from '@/components/NoQA.vue'
import Loader from '@/components/Loader.vue'
import Paging from '@/components/Paging.vue'
import TrimmedAnswer from '@/components/TrimmedAnswer.vue'

export default {
  data () {
    return {
      merchantcode: null,
      sku: null,
      domain: null,
      searchTerm: null,
      question: null,
      debounce: null,
      timer: 1000,
      showButton: false,
      authorized: false,
      allQA: null,
      limit: 5,
      offset: 0,
      loading: true,
      searchLoading: false,
      currentIndex: 0,
      showingFrom: 1,
      showingTill: 5,
      allRecords: 0,
      hideAnswer: true,
      widgetStyles: {}
    }
  },
  methods: {
    searchQuestion: function (event) {
      clearTimeout(this.debounce) // If key pressed, Remove the scheduled function.

      if (typeof (this.searchTerm) !== 'undefined' || this.searchTerm !== null) {
        this.question = this.searchTerm.trim()
      }

      if (this.question === '') {
        this.debounce = setTimeout(() => { //  setting the timer to avoid multiple calls to server
          this.searchLoading = true
          this.displayQuestions()
        }, this.timer)
      }

      if (this.question.length >= 3) { // search only when atleast 3 characters are entered
        this.showButton = true // showing the ask question button
        this.offset = 0 // Resetting the offset

        if (event.keyCode === 37 || event.keyCode === 30 || event.keyCode === 17) { // Left, Right, CTRL
          return
        }

        if (event.keyCode === 32 || event.keyCode === 13) { // If space/enter clicked then call display function immediately
          // this.$Console.log(this.question)
          this.searchLoading = true
          this.displayQuestions()
        } else {
          this.debounce = setTimeout(() => { //  setting the timer to avoid multiple calls to server
            // this.$Console.log(this.question + 'you hit')
            this.searchLoading = true
            this.displayQuestions()
          }, this.timer)
        }
      }
    },
    clearSearch: function (event) {
      this.question = ''
      this.searchTerm = ''
      this.searchLoading = true
      this.displayQuestions(event)
      this.showButton = false
    },
    paginateSearch: function (offset, index) {
      this.offset = offset
      this.currentIndex = index
      this.searchLoading = true
      this.showingFrom = offset + 1

      this.showingTill = this.maxLimit
      this.displayQuestions()
    },
    displayQuestions: function (event) {
      this.$axios
        .get(this.$siteURL + 'allqa/' + this.merchantcode + '/' + this.sku + '/' + this.domain + '?api_token=' + this.$root.token, {
          params: {
            offset: this.offset,
            limit: this.limit,
            searchTerm: this.question
          }
        })
        .then(response => {
          this.authorized = true

          this.allRecords = 0
          if (response.data.totalRecords > 0) {
            this.allRecords = response.data.totalRecords
          }
          this.showingTill = this.maxLimit
          this.allQA = response
          this.$Console.log(response)
        })
        .catch(error => {
          this.$Console.log(error)
        })
        .finally(() => {
          this.loading = false
          this.searchLoading = false
        })
    },
    substrAnswer: function (answer) {
      if (answer.length > 220) {
        return answer.substr(0, 220) + ' ... '
      } else {
        return answer
      }
    },
    isTrimmed: function (answer) {
      if (answer.length > 220) {
        return true
      } else {
        return false
      }
    },
    customerQuestion: function (question, questionPlain) {
      if (questionPlain) {
        return questionPlain
      } else {
        return question
      }
    }
  },
  computed: {
    maxLimit: function () {
      let maxLimit = this.limit - 1
      if ((this.showingFrom + maxLimit) > this.allRecords) {
        maxLimit = this.allRecords
      } else {
        maxLimit = this.showingFrom + maxLimit
      }
      return maxLimit
    }
  },
  mounted () {
    this.merchantcode = this.$route.params.merchantcode
    this.sku = this.$route.params.sku
    this.domain = this.$route.params.domain

    this.displayQuestions()
  },
  components: {
    Votes,
    Helpful,
    NoQA,
    Loader,
    Paging,
    TrimmedAnswer
  }
}
</script>
