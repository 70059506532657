<template>
<div :style="$root.bgColor" style="padding:0px;margin:0px">
  <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: null
    }
  },
  mounted () {
    this.$axios
      .get(this.$siteURL + 'widgetlookfeel?api_token=' + this.$root.token, {
        params: {
          merchantcode: this.$route.params.merchantcode
        }
      })
      .then(response => {
        if (response.data) {
          this.$root.widgetStyles = response.data
        }

        // this.$Console.log(response)
      })
      .catch(error => {
        this.$Console.log(error)
      })
      .finally(() => {

      })
  }
}
</script>
