<template>
  <div v-if="authorized">
    <a v-if="totalQuestions>0" :style="$root.primaryColor" href="#tab-label-questionstab-title">{{totalQuestions}} answered questions</a>
    <a v-else :style="$root.primaryColor" href="#tab-label-questionstab-title">Ask a question</a>
  </div>

</template>

<script>

export default {
  data () {
    return {
      merchantcode: null,
      sku: null,
      domain: null,
      allRecords: 0,
      limit: 5,
      offset: 0,
      authorized: false
    }
  },
  methods: {
    displayMicroQA: function (event) {
      this.$axios
        .get(this.$siteURL + 'allqa/' + this.merchantcode + '/' + this.sku + '/' + this.domain + '?api_token=' + this.$root.token, {
          params: {
            offset: this.offset,
            limit: this.limit
          }
        })
        .then(response => {
          this.authorized = true
          this.allRecords = 0
          if (response.data.totalRecords > 0) {
            this.allRecords = response.data.totalRecords
          }

          // this.$Console.log(response)
        })
        .catch(error => {
          this.$Console.log(error)
        })
    }
  },
  computed: {
    totalQuestions: function () {
      if (this.allRecords > 1000) {
        return '1000+'
      } else {
        return this.allRecords
      }
    }
  },
  mounted () {
    this.merchantcode = this.$route.params.merchantcode
    this.sku = this.$route.params.sku
    this.domain = this.$route.params.domain
    this.displayMicroQA()
  },
  components: {

  }
}
</script>
